export default {
  data() {
    return {
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
    };
  },
  methods: {
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
  },
};
